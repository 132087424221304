.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.white-content {
  background: #e8e8ee;
  color: #212b41;  
}
.white-content a{
  color: #212b41;
}
.white-content a p{
  color: #212b41;
}
.white-content .workbox-info p, .workbox-info h4{
  color: #dddddd;
}
.white-content svg path{
  fill: #525f7f;
}
.white-content .interaction-button img:hover{
  background: #2b4276;
  box-shadow: 0 0 5px #2b4276,
              0 0 10px #2b4276,
              0 0 15px #2b4276,
              0 0 30px #2b4276;
}

.white-content .Skills ul li {
  color: #ffffff;
}
.white-content .Skills ul li:hover {
  color: #525f7f;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.main-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-container header, section{
    width: 80%;
    margin: 20px;
}
.main-container section{ 
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
}

.fade-enter {
  transform: translateX(25%);
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 400ms ease-in, transform 400ms ease-in;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-25%);
  transition: opacity 400ms ease-out, transform 400ms ease-out;
}


@media screen and (max-width: 1200px) {
  .main-container header, section{
    width: 85%;
    margin: 15px;
  }
}

@media screen and (max-width: 768px) {
  .main-container header, section{
    width: 95%;
    margin: 15px;
  }
}