body {
  margin: 0;
  font-family: "Roboto","Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #dddddd;
  text-align: left;
  background: linear-gradient(#01070c, #021618, #080a24, #00071a);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
